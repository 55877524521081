import React, { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { App } from "components/App/App"
import { Query } from "typings/graphql"
import { graphql } from "gatsby"
import { setTheme } from "helpers/Theme"

const LoginWithRedirect: React.FC = () => {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    loginWithRedirect({
      redirectUri: window.location.origin,
    })
  }, [])

  return null
}

type PageProps = {
  data: Query
}

const LoginPage: React.FC<PageProps> = ({ data }) => {
  const theme = setTheme(
    data.sanityTypography,
    data.sanityDarkTheme,
    data.sanityLightTheme,
    data.sanityGlobalTheme
  )

  // Need to wrap with App to have the providers
  // but this just redirects to auth0 so no need to display the header etc.
  return (
    <div style={{ display: "none" }}>
      <App theme={theme} layout="page" mode="dark">
        <LoginWithRedirect />
      </App>
    </div>
  )
}

export const pageQuery = graphql`
  query LoginPage {
    sanityTypography {
      ...Typography
    }
    sanityDarkTheme {
      ...DarkTheme
    }
    sanityLightTheme {
      ...LightTheme
    }
    sanityGlobalTheme {
      ...GlobalTheme
    }
  }
`

export default LoginPage
